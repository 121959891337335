import { useEffect, useState } from 'react';
import Navbar from './Navbar';
import ScrollToTop from './ScrollToTop';
import './Header.css';

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window.addEventListener("scroll", updateScrollDirection); // add event listener

    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    }
  }, [scrollDirection]);

  return scrollDirection;
};

const Header = (props) => {
  const scrollDirection = useScrollDirection();

  return (
    <>
      <div id="header" className={`header ${scrollDirection === 'down' && 'header-hide'}`}>
        <Navbar />
      </div>
      <ScrollToTop />
    </>
  )
};

export default Header;
