import React from "react";
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FaMapSigns, FaMapMarkedAlt, FaList } from "react-icons/fa";
import LocationStatic from "./LocationStatic";
import Map from './Map';
import './Tabset.css';

const Tabset = () => {
  const { t } = useTranslation();

  return (
    <section id="tabs" className="tabs t-center">
      <div className="section-content">
        <h2>{t('ATM Locations')}</h2>
        <div> {t('Find a Bitcoin ATM near you')}</div>
        <FaMapSigns className="locations-icon" />
        <Tabs className="Tabs">
          <TabList>
            <Tab>
              <FaList className="tab-icon" /> {t('List')}
            </Tab>
            <Tab>
            <FaMapMarkedAlt className="tab-icon" /> {t('Map')}
            </Tab>
          </TabList>
          <TabPanel>
            <LocationStatic />
          </TabPanel>
          <TabPanel>
            <Map />
          </TabPanel>
        </Tabs>
      </div>
    </section>
  )
};

export default Tabset;