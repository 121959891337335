import i18n from "i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
/* const resources = {
  en: {
    translation: {
      "Buy and Sell Cryptocurrency": "Buy and Sell Cryptocurrency"
    }
  },
  ar: {
    translation: {
      "Buy and Sell Cryptocurrency": "Գնել և վաճառել կրիպտոարժույթը"
    }
  },
  ru: {
    translation:  {
        "Buy and Sell Cryptocurrency": "Купить и продать криптовалюту"
    }
  }
}; */

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector    
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options    
    .init({
        //resources, // uses the translations resource object
        //lng: "ar", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        fallbackLng: 'ar',
        debug: true,

        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });

export default i18n;