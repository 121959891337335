import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import geoJson from '../locations-am.json';
import 'mapbox-gl/dist/mapbox-gl.css';
import Marker from '../marker-48.png';
import './Map.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiY2FyYm9uc3BhY2UiLCJhIjoiY2w5ZWs5c3gxMDA2ZjN2bXhjbHQxb2RreSJ9.iDvknh5ZzRF8gyC1lFWsmQ';

const Map = () => {
  const mapContainerRef = useRef(null);

  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [44.508124, 40.190491],
      zoom: 13,
    });

    // https://www.iconsdb.com/custom-color/map-marker-2-icon.html
    const imageMarker = new Image(38, 38);
    imageMarker.src = Marker;
    // imageMarker.src = SVGMarker;

    map.on("load", function () {
      // Add custom marker
      map.addImage("custom-marker", imageMarker);
      // Add GeoJSON source with location points
      map.addSource("points", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: geoJson.features,
        },
      });
      // Add a symbol layer
      map.addLayer({
        id: "points",
        type: "symbol",
        source: "points",
        layout: {
          "icon-image": "custom-marker",
          "icon-allow-overlap": true,
          'text-allow-overlap': true,
          // get the title name from the source's "title" property
          "text-field": [
            'format', 
            ["get", "title"], 
            { 'font-scale': 0.8 }
          ],
          "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          "text-offset": [0, 1.25],
          "text-anchor": "top",
        },
      });

      // When a click event occurs on a feature in the places layer, open a popup at the
      // location of the feature, with description HTML from its properties.
      map.on('click', 'points', (e) => {
        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice();
        const p = e.features[0].properties;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML('<b>' + p.title + '</b><br >' + p.address + '<br />' + p.city + ', ' + p.state + '<br />' + p.zip)
          .addTo(map);
      });

      // Change the cursor to a pointer when the mouse is over the places layer.
      map.on('mouseenter', 'points', () => {
        map.getCanvas().style.cursor = 'pointer';
      });

      // Change it back to a pointer when it leaves.
      map.on('mouseleave', 'points', () => {
        map.getCanvas().style.cursor = '';
      });
    }); // end map.onLoad

    // Add Full Screen Controls
    // map.addControl(new mapboxgl.FullscreenControl({ container: document.querySelector('.map') }));

    // Add geolocate control to the map.
/*     map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true
      })
    ); */

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), "top-right");

    // Clean up on unmount
    return () => map.remove();
  }, []);

  return (
    <section id="map" className="map t-left">
      <div className="map-container" ref={mapContainerRef} />
    </section>
  );
};

export default Map;
