import React from "react";

import Intro from "./components/Intro";
import Features from "./components/Features";
import Atm from "./components/Atm";
import Tabset from "./components/Tabset";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

const Components = {
    intro: Intro,
    features: Features,
    atm: Atm,
    tabset: Tabset,
    contact: Contact,
    footer: Footer,  
};

// eslint-disable-next-line
export default block => {
  if (typeof Components[block.component] !== "undefined") {
    return React.createElement(Components[block.component], {
      key: block._uid,
      block: block
    });
  }
  
  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    { key: block._uid }
  );
};

//export default block;