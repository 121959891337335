import { useTranslation } from 'react-i18next';
import { FaUserClock, FaUserShield, FaUserFriends, FaUserPlus } from "react-icons/fa";

const Features = (props) => {
  const { t } = useTranslation();

  return (
    <section id="features" className="features t-center">
      <div className="col-wrapper">
        <div className="col">
          <FaUserClock className="feature-icon" />
          <h3>{t('Fast, Easy and Convenient')}</h3>
          {t('Simple and quick. Don\'t wait weeks for account verification, start buying and selling crypto within minutes. ')}
        </div>
        <div className="col">
          <FaUserShield className="feature-icon" />
          <h3>{t('Private & Secure')}</h3>
          {t('You can use the crypto wallet of your choosing and we do not own or hold your virtual currency, which means encrypted transactions are more secure. ')} 
          {t('No credit card or bank account information required, just bring cash. ')}
        </div>
      </div>
      <div className="col-wrapper mt-md">
        <div className="col">
          <FaUserFriends className="feature-icon" />
          <h3>{t('24/7 Customer Support')}</h3>
          {t('Email us, Open a ticket, or call us Mon - Friday ')} 
        </div>
        <div className="col">
          <FaUserPlus className="feature-icon" />
          <h3>{t('OTC Trading')}</h3>
          {t('Our platform facilitates trading across a broad range of cryptocurrencies, including Bitcoin, Litecoin, and Ethereum. ')} 
          {t('It is our mission to provide our clients with deep liquidity, personalized cryptocurrency trading support, and the highest standard of security and compliance. ')}
        </div>
      </div>      
      <img
        style={{ width: '80%' }}
        src={props.block.img}
        alt="Features"
      />
    </section>
  )
};

export default Features;
