import { useState } from "react";
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo.png';
import './Navigation.css';

function Navbar() {
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    return (
        <nav className="navigation">
            <a href="/" className="brand-name">
                <img
                    style={{ width: '80px' }}
                    src={logo}
                    alt="Cryptomat.am"
                />
            </a>
            <button
                className="hamburger"
                onClick={() => {setIsNavExpanded(!isNavExpanded)}}
            >
                {/* icon from Heroicons.com */}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="white"
                >
                    <path
                        fillRule="evenodd"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>
            <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
                <ul>
                    <li>
                        <Link 
                            activeClass="active" 
                            smooth spy to="intro" 
                            onClick={() => {setIsNavExpanded(!isNavExpanded)}}
                        >
                            {t('Intro')}
                        </Link>
                    </li>
                    <li>
                        <Link 
                            activeClass="active" 
                            smooth spy to="features" 
                            onClick={() => {setIsNavExpanded(!isNavExpanded)}}
                        >
                            {t('Features')}
                        </Link>
                    </li>
                    <li>
                        <Link 
                            activeClass="active" 
                            smooth spy to="tabs" 
                            onClick={() => {setIsNavExpanded(!isNavExpanded)}}
                        >
                            {t('ATM Locations')}
                        </Link>
                    </li>
                    <li>
                        <Link 
                            activeClass="active" 
                            smooth spy to="contact" 
                            onClick={() => {setIsNavExpanded(!isNavExpanded)}}
                        >
                            {t('Contact')}
                        </Link>
                    </li>
                    <li>
                        <button 
                            type="button" 
                            className="button button-locale" 
                            onClick={() => {changeLanguage('ar'); setIsNavExpanded(!isNavExpanded)}}
                        >
                            հայերեն
                        </button>
                        <button 
                            type="button" 
                            className="button button-locale" 
                            onClick={() => {changeLanguage('ru'); setIsNavExpanded(!isNavExpanded)}}
                        >
                            Русский
                        </button>
                        <button 
                            type="button" 
                            className="button button-locale" 
                            onClick={() => {changeLanguage('en'); setIsNavExpanded(!isNavExpanded)}}
                        >
                            English
                        </button>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;