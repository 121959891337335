import React from "react";
import { useTranslation } from 'react-i18next';
import { FaWallet, FaQrcode, FaCoins } from "react-icons/fa";

const Atm = (props) => {
  const { t } = useTranslation();

  return (
    <section id="atm" className="atm t-center">
      <div>
        <h2>{t('Our ATM')}</h2>
        <div className="section-content">
          {t('You can buy Bitcoin and other cryptocurrencies instantly with cash at our ATM locations. ')}  
          {t('Our ATMs offer the fastest, safest and most secure way to invest in cryptocurrencies today! ')} 
        </div>
      </div>
      <div>
        <img
          style={{ width: '300px', marginTop: '40px' }}
          src={props.block.img}
          alt="ATM"
        />
      </div>
      
      <h3>{t('How it Works')}</h3>
      <div className="section-content t-left">
        <div className="col-inline">
          <FaWallet className="how-icon" />
          <h4>{t('Install a Crypto Wallet App')}</h4>
        </div>
        <div>
          {t('First, install a secure crypto wallet app. ')} 
          {t('A wallet is where you will store your coins. Only you have access to this account. ')} 
        </div>  
        <div className="col-inline mt-md">
          <FaQrcode className="how-icon" />
          <h4>{t('Scan your QR Code')}</h4>
        </div> 
        <div>
          {t('Then, scan your QR code wallet address at the ATM and insert cash. ')}
          {t('Get started quickly with only a verified phone number. ')}  
        </div> 
        <div className="col-inline mt-md">
          <FaCoins className="how-icon" />
          <h4>{t('Insert Cash and Receive Coins')}</h4>
        </div>
        <div>
          {t('Choose your cryptocurrency, insert cash and select Finish. Coins are Sent. ')}
          {t('Purchase is confirmed and coins are sent immediately. ')} 
        </div>                                     
      </div>

      <div>
        <img
          style={{ width: '300px', marginTop: '40px' }}
          src={props.block.img2}
          alt="ATM"
        />
      </div>      

    </section>
  )
};

export default Atm;