import React from "react";
import { useTranslation } from 'react-i18next';

 const Contact = (props) => {
    const { t } = useTranslation();

    return (
        <section id="contact" className="contact t-center">
            <img
                style={{ width: '15%', marginBottom: '1rem' }}
                src={props.block.logo}
                alt="Contact"
            /> 
            <div className="section-content">{t('The fastest, safest and most secure way to manage your crypto. ')}</div>
            <img
                style={{ width: '40%' }}
                src={props.block.img}
                alt="Contact"
            />    
        </section>
    )
 };

export default Contact;