import React from "react";
import { useTranslation } from 'react-i18next';
import './Footer.css';

const Footer = (props) => {
    const { t } = useTranslation();

    return (
        <div id="footer" className="footer">
            <img
                style={{ width: '50px', height: '42px' }}
                src={props.block.img}
                alt="Cryptomat"
            />
            <div>{t('Cryptomat.am © Copyright 2022. All Rights Reserved. ')}</div>
        </div>
    )
};

export default Footer;