import React from 'react';
import './App.css';
import Components from './components.js';
import Header from './components/Header';

const data = {
  content: {
    body: [
      {
        _uid: "1",
        component: "intro",
        title: "Intro Title",
        img: require('./assets/intro.png'),
        url: "https://www.getcoins.com",
      },
      {
        _uid: "2",
        component: "features",
        title: "Features",
        img: require('./assets/features.png'),
      },
      {
        _uid: "3",
        component: "atm",
        title: "ATM",
        img: require('./assets/kiosk.png'),
        img2: require('./assets/atm.png'),
      },
      {
        _uid: "4",
        component: "tabset",
        title: "Tabset",
      },      
      {
        _uid: "5",
        component: "contact",
        title: "Contact",
        img: require('./assets/contact.png'),
        logo: require('./assets/logo.png'),
      },
      {
        _uid: "6",
        component: "footer",
        title: "Footer",
        img: require('./assets/logo.png'),
      },

      
    ]
  }
};

function App() {
  return (
    <div className="App">
      <Header />      
      {data.content.body.map(block => Components(block))}
      {/* <section id="intro">
        <Intro />
      </section>
      <section id="features">Features</section>
      <section id="atm">Our ATM</section>
      <section id="contact">Contact</section> */}
    </div>
  );
}

export default App;
