import { useTranslation } from 'react-i18next'
import { FaMapMarkerAlt, FaDirections, FaMobileAlt } from "react-icons/fa"
import './Locations.css'
import NorthAvenueHotel from '../assets/kiosks/hotel-north-avenue.jpg'
import NorthAvenueHotelKiosk from '../assets/kiosks/hotel-north-avenue-kiosk.jpg'

import RoyalPlazaHotelFront from '../assets/kiosks/royal-plaza-hotel-front.jpg'
import RoyalPlazaHotelKioskLeft from '../assets/kiosks/royal-plaza-hotel-kiosk-left.jpg'
import RoyalPlazaHotelKioskRight from '../assets/kiosks/royal-plaza-hotel-kiosk-right.jpg'

import AygedzorSupermarketFront from '../assets/kiosks/aygedzor-supermarket-front.jpg'
import AygedzorSupermarketAngle from '../assets/kiosks/aygedzor-supermarket-front-angle.jpg'
import AygedzorSupermarketDaytime from '../assets/kiosks/aygedzor-supermarket-front-daytime.jpg'
import AygedzorSupermarketKioskFront from '../assets/kiosks/aygedzor-supermarket-kiosk-front.jpg'
import AygedzorSupermarketKioskLeft from '../assets/kiosks/aygedzor-supermarket-kiosk-left.jpg'
import AygedzorSupermarketKioskRight from '../assets/kiosks/aygedzor-supermarket-kiosk-right.jpg'

import RioMallFront from '../assets/kiosks/rio-mall-front.jpg'
import RioMallEvent from '../assets/kiosks/rio-mall-event.jpg'
import RioMallKiosk from '../assets/kiosks/rio-mall-kiosk.jpg'
import RioMallKioskLeft from '../assets/kiosks/rio-mall-kiosk-left.jpg'
import RioMallKioskRight from '../assets/kiosks/rio-mall-kiosk-right.jpg'
import RioMallKioskWide from '../assets/kiosks/rio-mall-kiosk-wide.jpg'

const LocationStatic = (props) => {
  const { t } = useTranslation();

  return (
    <section id="locations" className="locations t-center">
      <div className="section-content">
        <ul>
          <li>
            <div className="location">
              <div><FaMapMarkerAlt className="location-icon" /></div>
              <div>
                <p className="location-name">{t('North Avenue Hotel')}</p>
                <p>{t('10, 1 Northern Ave')}</p>
                <p>{t('Yerevan 0010, Armenia')}</p>
                <hr />
                <p><FaDirections className="contact-detail" />
                  <a href={'http://www.google.com/maps/place/40.1808962,44.5147216'} target="_blank" rel="noreferrer">
                    40.1808962, 44.5147216
                  </a>
                </p>
                <p><FaMobileAlt className="contact-detail" />
                  <a href="https://northavenue.ru/" target="_blank" rel="noreferrer">
                    northavenue.ru
                  </a>
                </p>
                <div className="location-images">
                  <img src={NorthAvenueHotel} alt="Cryptomat Kiosk - North Avenue Hotel, Yerevan" style={{width: '94.5%'}} />
                  <img src={NorthAvenueHotelKiosk} alt="Cryptomat Kiosk - North Avenue Hotel, Yerevan" />
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="location">
              <div><FaMapMarkerAlt className="location-icon" /></div>
              <div>
                <p className="location-name">{t('Royal Plaza Hotel')}</p>
                <p>{t('9 Martiros Saryan St')}</p>
                <p>{t('Yerevan 0002, Armenia')}</p>
                <hr />
                <p><FaDirections className="contact-detail" />
                  <a href={'http://www.google.com/maps/place/40.1865182,44.5086998'} target="_blank" rel="noreferrer">
                  40.1865182, 44.5086998
                  </a>
                </p>
                <p><FaMobileAlt className="contact-detail" />
                  <a href="http://royalplaza-stellarhotels.htkornet.ru/" target="_blank" rel="noreferrer">
                    royalplaza-stellarhotels.htkornet.ru
                  </a>
                </p>
                <div className="location-images">
                  <img src={RoyalPlazaHotelFront} alt="Cryptomat Kiosk - Royal Plaza Hotel, Yerevan" />
                  <img src={RoyalPlazaHotelKioskLeft} alt="Cryptomat Kiosk - Royal Plaza Hotel, Yerevan" />
                  <img src={RoyalPlazaHotelKioskRight} alt="Cryptomat Kiosk - Royal Plaza Hotel, Yerevan" />
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="location">
              <div><FaMapMarkerAlt className="location-icon" /></div>
              <div>
                <p className="location-name">{t('Aygedzor Supermarket')}</p>
                <p>{t('2, 1 Proshyan St')}</p>
                <p>{t('Yerevan 0019, Armenia')}</p>
                <hr />
                <p><FaDirections className="contact-detail" />
                  <a href={'http://www.google.com/maps/place/40.1910225,44.5040435'} target="_blank" rel="noreferrer">
                    40.1910225, 44.5040435
                  </a>
                </p>
                <div className="location-images">
                  <img src={AygedzorSupermarketFront} alt="Cryptomat Kiosk - Aygedzor Supermarket, Yerevan" />
                  <img src={AygedzorSupermarketAngle} alt="Cryptomat Kiosk - Aygedzor Supermarket, Yerevan" />
                  <img src={AygedzorSupermarketDaytime} alt="Cryptomat Kiosk - Aygedzor Supermarket, Yerevan" />
                  <img src={AygedzorSupermarketKioskFront} alt="Cryptomat Kiosk - Aygedzor Supermarket, Yerevan" />
                  <img src={AygedzorSupermarketKioskLeft} alt="Cryptomat Kiosk - Aygedzor Supermarket, Yerevan" />
                  <img src={AygedzorSupermarketKioskRight} alt="Cryptomat Kiosk - Aygedzor Supermarket, Yerevan" />
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="location">
              <div><FaMapMarkerAlt className="location-icon" /></div>
              <div>
                <p className="location-name">{t('Rio Mall')}</p>
                <p>{t('8 Vahram Papazyan St')}</p>
                <p>{t('Yerevan 0012, Armenia')}</p>
                <hr />
                <p><FaDirections className="contact-detail" />
                  <a href={'http://www.google.com/maps/place/40.2015252,44.5048465'} target="_blank" rel="noreferrer">
                    40.2015252, 44.5048465
                  </a>
                </p>
                <div className="location-images">
                  <img src={RioMallFront} alt="Cryptomat Kiosk - Rio Mall, Yerevan" />
                  <img src={RioMallEvent} alt="Cryptomat Kiosk - Rio Mall, Yerevan" />
                  <img src={RioMallKiosk} alt="Cryptomat Kiosk - Rio Mall, Yerevan" style={{width: '94.5%'}} /><br />
                  <img src={RioMallKioskLeft} alt="Cryptomat Kiosk - Rio Mall, Yerevan" />
                  <img src={RioMallKioskRight} alt="Cryptomat Kiosk - Rio Mall, Yerevan" />
                  <img src={RioMallKioskWide} alt="Cryptomat Kiosk - Rio Mall, Yerevan" />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
};

export default LocationStatic;