import React from "react";
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

const Intro = (props) => {
    const { t } = useTranslation();

    return (
        <section id="intro" className="intro t-center">
            <h1>{t('Buy and sell cryptocurrency')}</h1>
            <div>{t('The fastest and easiest way to buy and sell cryptocurrency with cash near you. ')}</div>
            <div className="section-content">
                <img
                    style={{ width: '70%' }}
                    src={props.block.img}
                    alt="Cryptomat"
                />
                <div className="button-wrapper">
                    <button className="button">
                        <Link className="button" activeClass="active" smooth spy to="tabs">
                            {t('Find ATM Near You')}
                        </Link>
                    </button>
                </div>
            </div>
        </section>
    )
};

export default Intro;
